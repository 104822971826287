import { ICalendlySliceState } from './calendly.slice';
import { calendlyStoreKey } from './calendly.const';
import { isStatusLoading } from '../../utils';
import { API_STATUS } from '../../constants';

interface IState {
  [calendlyStoreKey]: ICalendlySliceState;
}

const selectCalendlyState = (state: IState) => {
  return state[calendlyStoreKey];
};

const getCalendlyIsLoading = (state: IState): boolean => {
  return isStatusLoading(selectCalendlyState(state).apiStatus);
};

const getApiStatus = (state: IState): API_STATUS => {
  return selectCalendlyState(state).apiStatus;
};

const getCalendlyLink = (state: IState): string | null => {
  return selectCalendlyState(state).data?.link ?? null;
};

const getHasNoAvailableSlots = (state: IState): boolean => {
  return selectCalendlyState(state).hasNoAvailableSlots;
};

export const calendlySelectors = {
  getCalendlyIsLoading,
  getApiStatus,
  getCalendlyLink,
  getHasNoAvailableSlots,
};
