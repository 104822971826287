import { Flex, ResponsiveBreak, StepPanel } from 'src/components';
import { Box, Typography } from '@mui/material';
import LetsConnect from 'src/features/letsConnect/LetsConnect';

import startImage from '../../../../assets/passing-star.png';

export const NoAvailableSlots = () => {
  return (
    <StepPanel>
      <Flex
        justifyContent="space-between"
        textAlign="left"
        flexDirection="column"
        gap={3}
      >
        <Box
          component="img"
          src={startImage}
          alt="Star"
          width={{ xs: 57, sm: 67, xxl: 100 }}
          height={{ xs: 57, sm: 67, xxl: 100 }}
        />
        <Typography variant="h2" mb={0} data-testid="success-title">
          Great! Next step will <ResponsiveBreak breakpoint="xs" />
          be a quick intro call
        </Typography>
        <Typography variant="body1" textAlign="center">
          Please expect an email invitation from us as soon as we process your
          application and our Talent Executive will discuss details with you.
        </Typography>
        <Typography variant="body2" textAlign="center" color="text.secondary">
          Due to a high number of applicants in your location it can take
          several days. We’re working hard on processing as many incoming
          applications as we can.
        </Typography>
      </Flex>
      <LetsConnect textAlign="left" mt={8} width="100%" />
    </StepPanel>
  );
};
