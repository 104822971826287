import { Box, Typography } from '@mui/material';

const ReachOutToUs = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        margin: '0 auto',
        textAlign: 'left',
        px: 2,
      }}
    >
      <Typography variant="body2" color="text.secondary" lineHeight={1.4}>
        Don't see an open slot?
      </Typography>

      <Typography variant="body2" color="text.secondary" lineHeight={1.4}>
        It's probably due to a high number <br /> of applicants in your
        location. We're <br />
        working hard on processing as many <br /> incoming applications as we
        can.
      </Typography>

      <Typography variant="body2" color="text.secondary" lineHeight={1.4}>
        Please be sure to check the schedule <br /> at the end of the day or the
        next day.
      </Typography>
    </Box>
  );
};

export default ReachOutToUs;
