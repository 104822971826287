import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  PageTransitionWrapper,
  FileInput,
  GoToNextStep,
  GoToPreviousStep,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateProfessionalIdentity, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSlice,
  gamifiedResponseSelectors,
} from '../../redux/gamifiedResponse';
import {
  usePersistUserAnswers,
  useDebounce,
  useFeatureFlags,
} from '../../hooks';

import { validationSchema } from './validationSchema';
import { IProfessionalIdentityBody, ZohoCandidateTypes } from '../../types';
import { getUserPrefferedName } from '../../utils';
import { logEvent } from 'src/services';

const ProfessionalIdentity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showAdditionalProfessionalLinkField } = useFeatureFlags();

  const userData = useAppSelector(userSelectors.getUserData)!;
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );

  const [savedProfessionalId, setSavedProfessionalId] =
    usePersistUserAnswers<IProfessionalIdentityBody>('identityStep');

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const isLinkedInVisible =
    userData?.zohoUserType !== ZohoCandidateTypes.ExistingSignedToLpWithZohoId;

  useEffect(() => {
    if (userData.originalCvFileName) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else if (!lastGamifiedMessage) {
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          `Welcome back, ${getUserPrefferedName(userData)}!`,
        ),
      );
    }

    if (showAdditionalProfessionalLinkField) {
      logEvent(
        'launchpod - professional-identity-step-with-additional-link-field',
      );
    } else {
      logEvent(
        'launchpod - professional-identity-step-without-additional-link-field',
      );
    }

    logEvent('launchpod-professional-identity-page-loaded');
  }, []);

  const handleSubmit = (values: IProfessionalIdentityBody): void => {
    logEvent('launchpod-professional-identity-continue-click');

    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        'Awesome! No more questions.',
      ),
    );
    dispatch(
      updateProfessionalIdentity({
        ...values,
        onUploadProgress,
      }),
    );
  };

  const formik = useFormik<IProfessionalIdentityBody>({
    initialValues: {
      originalCvFileName: (userData?.originalCvFileName as File) ?? null,
      linkedinLink: savedProfessionalId?.linkedinLink || userData?.linkedinLink,
      additionalProfessionalLink:
        savedProfessionalId?.additionalProfessionalLink ||
        userData?.additionalProfessionalLink,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setSavedProfessionalId(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.linkedinLink && !formik.values.linkedinLink.trim()) {
      debouncedClearEmptyField('linkedinLink');
    }
  }, [formik.values.linkedinLink]);

  useEffect(() => {
    if (
      formik.values.additionalProfessionalLink &&
      !formik.values.additionalProfessionalLink.trim()
    ) {
      debouncedClearEmptyField('additionalProfessionalLink');
    }
  }, [formik.values.additionalProfessionalLink]);

  const debouncedClearEmptyField = useDebounce((fieldName) => {
    formik.setFieldValue(fieldName, null);
  }, 1000);

  const onUploadProgress = useCallback((e: ProgressEvent) => {
    const { loaded, total } = e;
    const progress = Math.round((100 * loaded) / total);
    setUploadProgress(progress);
  }, []);

  const isDisabled = !formik.isValid || !formik.values.originalCvFileName;

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="professional-identity-layout">
        <GoToPreviousStep />
        <Typography variant="h2" mb={2}>
          Do you want to showcase more{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          />{' '}
          of your professional identity?
        </Typography>
      </Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          spacing={'1.5rem'}
          my={'1rem'}
          sx={(theme) => ({
            maxWidth: '25rem',
            [theme.breakpoints.down('sm')]: {
              maxWidth: 'initial',
            },
          })}
        >
          <Stack spacing={'0.75rem'}>
            <Typography variant="body1" color="primary" sx={{ fontSize: 20 }}>
              Resume
            </Typography>
            <FileInput
              label="Attach resume"
              hint="PDF or DOC, 20 MB"
              name="originalCvFileName"
              onChange={(event) => {
                formik.setFieldValue(
                  'originalCvFileName',
                  event.target.files![0] ?? null,
                );
              }}
              value={[
                (formik.values.originalCvFileName as File)?.name ||
                  (formik.values.originalCvFileName as string) ||
                  '',
              ]}
              loading={true}
              progress={uploadProgress}
              setErrorInFile={(errors) =>
                errors[0] &&
                formik.setFieldError('originalCvFileName', errors[0])
              }
              error={{ 0: formik.errors.originalCvFileName ?? '' }}
              autoFocus
            />
          </Stack>
          {(isLinkedInVisible || showAdditionalProfessionalLinkField) && (
            <Stack spacing={'1rem'}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 20, marginBottom: '0.5rem' }}
                >
                  Links
                </Typography>
                <Typography variant="body2">
                  Help us better understand your professional life and share
                  more insights on your career and personality.
                </Typography>
              </Box>
              {isLinkedInVisible && (
                <TextField
                  name="linkedinLink"
                  type="text"
                  label="LinkedIn profile"
                  placeholder="https://www.linkedin.com/in/your-profile"
                  variant="outlined"
                  fullWidth
                  value={formik.values.linkedinLink ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.linkedinLink)}
                  helperText={formik.errors.linkedinLink}
                  InputProps={{
                    endAdornment: formik.values.linkedinLink ? null : (
                      <InputAdornment position="end">
                        <Typography variant="body2" color="secondary">
                          optional
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus={false}
                />
              )}
              {showAdditionalProfessionalLinkField && (
                <TextField
                  name="additionalProfessionalLink"
                  type="text"
                  label="GitHub, portfolio, or blog"
                  placeholder="https://www.github.com/your-profile"
                  variant="outlined"
                  fullWidth
                  value={formik.values.additionalProfessionalLink ?? ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.additionalProfessionalLink)}
                  helperText={formik.errors.additionalProfessionalLink}
                  InputProps={{
                    endAdornment: formik.values
                      .additionalProfessionalLink ? null : (
                      <InputAdornment position="end">
                        <Typography variant="body2" color="secondary">
                          optional
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus={false}
                />
              )}
            </Stack>
          )}
        </Stack>
      </form>
      <GoToNextStep
        isLoading={isLoading}
        isDisabled={isDisabled}
        textIdentifier={4}
        handleSubmitStep={() => formik.submitForm()}
      />
    </PageTransitionWrapper>
  );
};

export default ProfessionalIdentity;
