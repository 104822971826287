import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { calendlyStoreKey } from './calendly.const';
import { calendlyLink } from '../../constants/apiEndpoints';

export const getCalendlyLink = createAsyncThunk(
  `${calendlyStoreKey}/getCalendlyLink`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(calendlyLink());
      return response.data || null;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
