import React, { FC, useMemo } from 'react';
import { Box, BoxProps, SvgIcon, Typography } from '@mui/material';
import { APP_ROUTES, NOTICE_PERIOD_MAP } from '../../constants';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { RowLayout } from './RowLayout';
import { IUserData } from '../../types/user';
import { Flex } from '../flex/Flex';
import { TextButton } from '../textButton/TextButton';
import { useLPLocation, usePositionData } from '../../hooks';
import {
  formatCompensation,
  getCityAlias,
  getUserSkillsInfo,
  isSkipCityBasedOnCountry,
  isSkipEnglishLevelBasedOnCountry,
  isSkipSeniorityBasedOnExperience,
  isSkipSkills,
  isSkipSpecializationBasedOnUserPosition,
  isUserPositionPartial,
  removeHttpFromUrl,
} from '../../utils';

interface IProps extends Omit<BoxProps, 'children'> {
  user: IUserData;
  onDownloadCV?: () => void;
  onRedirectTo?: (appRoute: string) => void;
}
export const QuickReview: FC<IProps> = ({
  user,
  onDownloadCV,
  onRedirectTo,
  ...rest
}) => {
  const positionData = usePositionData();
  const { suggestedCountries } = useLPLocation();

  const isPartialUserPosition = isUserPositionPartial(positionData);
  const isSkipSpecialization =
    isSkipSpecializationBasedOnUserPosition(positionData);

  const handleSkillsChange = () => {
    if (!onRedirectTo) return;
    if (isSkipSpecialization) return;

    const appRoute = isUserPositionPartial(positionData)
      ? `${APP_ROUTES.TECH_SKILLS}/${APP_ROUTES.PRIMARY_SKILLS}`
      : APP_ROUTES.TECH_SKILLS;

    onRedirectTo(appRoute);
  };
  const { skills, extraItems } = useMemo(
    () => getUserSkillsInfo(user, 3),
    [user],
  );

  const resumeName = useMemo(
    () =>
      (user?.originalCvFileName as File)?.name ||
      (user?.originalCvFileName as string) ||
      '',
    [user],
  );

  return (
    <Box {...rest}>
      <RowLayout
        label="Email"
        Component={<Typography variant="body1">{user.email}</Typography>}
        onClick={onRedirectTo?.bind(null, APP_ROUTES.EMAIL)}
      />
      <RowLayout
        label="Name"
        Component={
          Boolean(user.nickname) ? (
            <>
              <Typography variant="body1">{user.nickname}</Typography>
              <Typography variant="body2" color="text.secondary">
                {[user.firstName, user.lastName].filter((e) => e).join(' ')}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">
              {[user.firstName, user.lastName].filter((e) => e).join(' ')}
            </Typography>
          )
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.NAME)}
      />
      <RowLayout
        label="Country"
        Component={
          <Typography variant="body1">{user?.country ?? ''}</Typography>
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.COUNTRY)}
      />
      {!isSkipCityBasedOnCountry(user?.country, suggestedCountries) && (
        <RowLayout
          label="City"
          Component={
            <>
              <Typography variant="body1">
                {getCityAlias(user?.city ?? '')}
              </Typography>
              {user.officeLocation !== user?.city && (
                <Typography variant="body2" color="text.secondary">
                  {user.officeLocation}
                </Typography>
              )}
            </>
          }
          onClick={onRedirectTo?.bind(null, APP_ROUTES.CITY)}
        />
      )}
      {!isSkipEnglishLevelBasedOnCountry(user?.country) && (
        <RowLayout
          label="English"
          Component={
            <Typography variant="body1">{user.englishLevel}</Typography>
          }
          onClick={onRedirectTo?.bind(null, APP_ROUTES.ENGLISH_LEVEL)}
        />
      )}
      <RowLayout
        label="Specialization"
        Component={
          <Typography variant="body1">{user.specialization}</Typography>
        }
        onClick={onRedirectTo && handleSkillsChange}
        isLocked={isSkipSpecialization || isPartialUserPosition}
        lockLabel="Required for your position"
      />
      {!isSkipSkills(user) && (
        <RowLayout
          label="Skills"
          Component={
            <>
              <Typography variant="body1">{skills}</Typography>
              {extraItems && (
                <Typography variant="body2" color="text.secondary">
                  {extraItems}
                </Typography>
              )}
            </>
          }
          onClick={onRedirectTo && handleSkillsChange}
        />
      )}
      <RowLayout
        label="Experience"
        Component={
          <Typography variant="body1">{user.yearsOfExperienceNote}</Typography>
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.EXPERIENCE)}
      />
      {!isSkipSeniorityBasedOnExperience(user?.yearsOfExperience) && (
        <RowLayout
          label="Seniority"
          Component={<Typography variant="body1">{user.seniority}</Typography>}
          onClick={onRedirectTo?.bind(null, APP_ROUTES.SENIORITY)}
        />
      )}
      <RowLayout
        label="Compensation"
        Component={
          <Typography variant="body1">${formatCompensation(user)}</Typography>
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.COMPENSATION)}
      />
      <RowLayout
        label="Notice period"
        Component={
          <Typography variant="body1">
            {NOTICE_PERIOD_MAP[user.noticePeriod!]}
          </Typography>
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.NOTICE_PERIOD)}
      />
      <RowLayout
        label="Resume"
        Component={
          onDownloadCV ? (
            <TextButton
              onClick={onDownloadCV}
              startIcon={<SvgIcon component={AttachFileIcon} />}
              sx={{ color: 'text.primary', fontWeight: 500 }}
              underlined
            >
              {resumeName}
            </TextButton>
          ) : (
            <Flex gap={0.5} ml={-1}>
              <SvgIcon component={AttachFileIcon} />{' '}
              <Typography variant="body1">{resumeName}</Typography>
            </Flex>
          )
        }
        onClick={onRedirectTo?.bind(null, APP_ROUTES.PROFESSIONAL_IDENTITY)}
        data-testid="edit-Professional-identity"
      />
      {user?.linkedinLink && (
        <RowLayout
          label="LinkedIn profile"
          Component={
            <Typography variant="body1">
              {removeHttpFromUrl(user.linkedinLink)}
            </Typography>
          }
          onClick={onRedirectTo?.bind(null, APP_ROUTES.PROFESSIONAL_IDENTITY)}
        />
      )}
      {user?.additionalProfessionalLink && (
        <RowLayout
          label="GitHub, portfolio, or blog"
          Component={
            <Typography variant="body1">
              {user.additionalProfessionalLink}
            </Typography>
          }
          onClick={onRedirectTo?.bind(null, APP_ROUTES.PROFESSIONAL_IDENTITY)}
        />
      )}
    </Box>
  );
};
