import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  ResponsiveBreak,
  StepPanelHeader,
} from '../../../../../components';

import startImage from '../../../../../assets/passing-star.png';
import { ReactComponent as CalendlyIcon } from '../../../../../assets/calendly-icon.svg';
import ReachOutToUs from '../ReachOutToUs';
import { logEvent } from 'src/services';

interface IProps {
  calendlyLink: string | null;
  isCalendlyLoading: boolean;
  setShowCalendlyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoTestTasksMessage: React.FC<IProps> = ({
  calendlyLink,
  isCalendlyLoading,
  setShowCalendlyModal,
}) => {
  const isShowCalendlyLink = typeof calendlyLink === 'string';
  const starRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        {
          <>
            Thank you <ResponsiveBreak breakpoint="xs" />
            for your application!
          </>
        }
      </StepPanelHeader>
      <Typography
        textAlign="center"
        variant="body1"
        data-testid="success-text"
        width="100%"
      >
        {isShowCalendlyLink ? (
          <>
            Our Talent Executive would be happy{' '}
            <ResponsiveBreak breakpoint="sm" />
            to have an intro call with you.
          </>
        ) : (
          <>
            Our Talent Executive will reach out{' '}
            <ResponsiveBreak breakpoint="md" />
            to you to discuss the next steps.
          </>
        )}
      </Typography>
      {isShowCalendlyLink && (
        <>
          <Box my={1} data-testid="calendly-button">
            <CustomButton
              color="primary"
              startIcon={
                <Flex bgcolor="white" borderRadius={2} p={0.5}>
                  <CalendlyIcon />
                </Flex>
              }
              label="Book a time slot"
              onClick={() => {
                logEvent('launchpod-book-time-slot-prescreen-call-clicked');
                setShowCalendlyModal && setShowCalendlyModal(true);
              }}
              isLoading={isCalendlyLoading}
            />
          </Box>
          <ReachOutToUs />
        </>
      )}
    </>
  );
};

export default NoTestTasksMessage;
