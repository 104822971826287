import {
  ADDITIONAL_LINK_ERROR_MESSAGE,
  LINKEDIN_ERROR_MESSAGE,
  LINKEDIN_REGEXP,
  URL_REGEXP,
} from '../../constants';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  originalCvFileName: yup
    .mixed()
    .test('file', 'Please, attach a file with your resume', (value) => {
      return Boolean(value);
    }),
  linkedinLink: yup
    .string()
    .nullable()
    .matches(LINKEDIN_REGEXP, LINKEDIN_ERROR_MESSAGE),
  additionalProfessionalLink: yup
    .string()
    .nullable()
    .matches(URL_REGEXP, ADDITIONAL_LINK_ERROR_MESSAGE),
});
